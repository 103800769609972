import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import 'typeface-roboto-slab'

import SEO from '../components/seo'
import Icon from './icon'
import { facebook, instagram, twitter } from './constants'

const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    background-color: #212121;
    color: #FAF0E6;
    font-family: 'Roboto Slab';
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }

  p {
    line-height: 1.25rem;
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
`

const SocialIcon = styled.li`
  display: inline-block;
  margin: 0 1rem 1rem;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

const FooterText = styled.p`
  margin: 0 1rem 1rem;
`

const ContentfulLink = styled.a`
  color: #FAF0E6;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          keywords
        }
      }
      contentfulContact {
        facebook
        instagram
        twitter
      }
    }
  `)

  return (
    <>
      <SEO
        meta={[
          {
            name: `keywords`,
            content: data.site.siteMetadata.keywords,
          }
        ]}
      />
      <GlobalStyle />
      <div>
        <main>{children}</main>
        <Footer>
          <ul style={{ marginBottom: `1rem` }}>
            <SocialIcon>
              <a
                href={data.contentfulContact.facebook}
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <Icon
                  icon={facebook}
                  colour="#FAF0E6"
                  size="24"
                />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                href={data.contentfulContact.instagram}
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <Icon
                  icon={instagram}
                  colour="#FAF0E6"
                  size="24"
                />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                href={data.contentfulContact.twitter}
                target="_blank"
                rel="noopener noreferrer"
                title="Twitter"
              >
                <Icon
                  icon={twitter}
                  colour="#FAF0E6"
                  size="24"
                />
              </a>
            </SocialIcon>
          </ul>
          <FooterText>© {new Date().getFullYear()} {data.site.siteMetadata.title}</FooterText>
          <FooterText>
            {`Powered by `}
            <ContentfulLink
              href="https://www.contentful.com"
              target="_blank"
              rel="noopener noreferrer"
              title="Contentful"
            >
              {`Contentful`}
            </ContentfulLink>
          </FooterText>
        </Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
