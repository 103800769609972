import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Svg = styled.svg`
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
	max-height: 100%;
`

const Path = styled.path`
	fill: ${ props => props.colour };
`

const Icon = ({ icon, colour, size, width, height, viewbox }) => (
	<Svg
		width={width ? width : size}
		height={height ? height : size}
		viewBox={viewbox}
	>
		<Path d={icon} colour={colour}></Path>
	</Svg>
)

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	colour: PropTypes.string,
	size: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	viewbox: PropTypes.string
}

Icon.defaultProps = {
	size: '16px',
	colour: '#1A202C',
	viewbox: '0 0 16 16'
}

export default Icon
